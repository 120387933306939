import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import YouTube from 'react-youtube';
import reserchingBro from "images/gptBubble/Researching-bro.svg";
import DownloadApp from "../components/cta/DownloadApp";
import tw from "twin.macro";
import {androidUrl} from "../constants";
import SingleCol from "../components/faqs/SingleCol";

const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;

export default () => {
    const opts = {
        height: '597',
        width: '277.03937008',

        playerVars: {
            autoplay: 1,
            loop: 1,
            showinfo: 0,
            controls: 0,
            rel: 0,
            mute: 1
        },
    }
  return (
    <AnimationRevealPage>
      <Hero
        heading="Your Ultimate Communication Companion"
        description="Revitalize Your Messaging with AI-powered Precision and Finesse."
        primaryButtonText="Power Up Your Communication!"
        primaryButtonUrl={androidUrl}
        imageSrc={reserchingBro}
      />
        <DownloadApp
            text={<>People around you are using <HighlightedTextInverse>GptBubble.</HighlightedTextInverse></>}
            image={<YouTube videoId="WMPINeZLo3A" opts={opts}  />}
        />
        <SingleCol />

      <Pricing
        heading={ "Exclusive Launch Offers"}
        description={ "GPT Bubble presents two special offers. The first 50 users get a lifetime subscription at a one-time payment of $50. Post this, enjoy our services at an affordable monthly subscription of $5.00/user. Experience smart messaging with GPT Bubble today."}
        plans={[
            {
                name: "Special Launch Offer",
                price: ["$50", ".00/user"],
                oldPrice: "Monthly Subscription",
                description: "A lifetime subscription to GPT Bubble, complete with free lifetime updates. This offer is available to our first 50 users for a one-time fee.",
                features: [
                    "Lifetime access to AI-powered communication enhancement",
                    "Free lifetime updates",
                    "Limited to the first 50 users",
                    "One-time payment"
                ],
                url: "URL for the offer" // Replace with actual URL
            },
            {
                name: "Post Launch Offer",
                price: ["$??", ".??/user/month"],
                description: "After the first 50 users, GPT Bubble will shift to a monthly subscription model.",
                features: [
                    "Monthly access to AI-powered communication enhancement",
                    "Available to all users after the first 50"
                ],
                url: "URL for the offer" // Replace with actual URL
            }
        ]}
      />

        <FAQ
            description="Here at GPT Bubble, we're always ready to answer your questions. Find the answers to frequently asked queries about the GPT Bubble app below. If you can't find the information you're looking for, feel free to get in touch with our support team."
            faqs={[
                {
                    question: "What is GPT Bubble?",
                    answer:
                        "GPT Bubble is a revolutionary communication tool that utilizes AI to refine your messaging. It helps to ensure the tone, style, and intent of your messages align perfectly with your purpose, whether it's professional, personal or casual communication."
                },
                {
                    question: "How do I use GPT Bubble?",
                    answer:
                        "To use GPT Bubble, simply download it from your app store, launch the app, and start composing your message. You can customize the tone of your message, and our AI will transform your text to match the tone. The refined message is then ready to be copied and pasted into your chosen communication platform."
                },
                {
                    question: "Does GPT Bubble work with all messaging platforms?",
                    answer:
                        "Yes, GPT Bubble works with any platform where you communicate via text. This includes, but isn't limited to, Whatsapp, Email, SMS, and various dating platforms."
                },
                {
                    question: "What are the pricing options for GPT Bubble?",
                    answer:
                        "GPT Bubble offers a special one-time fee of $50 for a lifetime subscription to the first 50 users. After that, we shift to a monthly subscription pricing model costing $5.00/user."
                },
                {
                    question: "Is there a special offer for early users?",
                    answer:
                        "Yes, our first 50 users can avail a lifetime subscription at a one-time fee of only $50. This includes free lifetime updates. After the 50-user mark, we'll shift to a monthly subscription pricing model."
                },
                {
                    question: "Does GPT Bubble respect my privacy?",
                    answer:
                        "Absolutely. GPT Bubble operates as a non-intrusive companion, respecting your privacy while enhancing your communication."
                },
            ]}
        />

        <Footer />
    </AnimationRevealPage>
  );
}
